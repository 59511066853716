import { Motion } from "./Options/Classes/Motion";
import { MotionInstance } from "./MotionInstance";
class MotionPlugin {
    constructor(engine) {
        this.id = "motion";
        this._engine = engine;
    }
    getPlugin(container) {
        return new MotionInstance(container, this._engine);
    }
    loadOptions(options, source) {
        if (!this.needsPlugin()) {
            return;
        }
        let motionOptions = options.motion;
        if ((motionOptions === null || motionOptions === void 0 ? void 0 : motionOptions.load) === undefined) {
            options.motion = motionOptions = new Motion();
        }
        motionOptions.load(source === null || source === void 0 ? void 0 : source.motion);
    }
    needsPlugin() {
        return true;
    }
}
export async function loadMotionPlugin(engine) {
    const plugin = new MotionPlugin(engine);
    await engine.addPlugin(plugin);
}
